<template>
	<MhRouterView class="HomeView view" :isContentReady="isContentReady">

		<div class="view__inner">

			<div class="view__panel view__panel--left scroller">
				<div class="HomeView__introText richText hSpace hSpace--content font font--sans font--bold font--sizeMedium" v-html="_.get( homepage, 'acf.introText' )"></div>
				<!--
				<pre name="homepage">{{homepage}}</pre>
				<pre name="app.acfOptions">{{app.acfOptions}}</pre>
				-->
			</div>

			<div class="view__panel view__panel--right">
				<!--
				<h1 class="HomeView__newsTitle font font--sans font--bold font--sizeBig hSpace hSpace--content">

				</h1>
				-->
				<TsPostHeader
					:mainCategories="false"
					:overTitle="''"
					:mainTitle="'News'"
				></TsPostHeader>

				<ol class="HomeView__newsPostsList hSpace hSpace--content">
					<TsNewsPostTeaser
						v-for="(post, index) in posts"
						:key="index"
						:tag="'li'"
						:post="post"
					></TsNewsPostTeaser>
				</ol>
			</div>


		</div>

		<!--
		<pre data-name="posts">{{posts}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MhRouterView from '@/components/MhRouterView/v2/MhRouterView.vue'
	import TsPostHeader from '@/molecules/TsPostHeader.vue'
	import TsNewsPostTeaser from '@/molecules/TsNewsPostTeaser.vue'

	export default {
		name: 'HomeView',
		components: {
			MhRouterView,
			TsPostHeader,
			TsNewsPostTeaser,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data(){
			return {
				isContentReady : false,
				posts          : undefined,
				homepage       : undefined,
			}
		},
		watch: {
			'app.acfOptions': {
				handler: function( to, from ) {
					if( to ) this.fetchPage()
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch news
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'post',
					},
					callback : (response) => {
						this.posts = response.data.result
						this.isContentReady = true
					},
				})
			},
			fetchPage(){
				const acfOptions = this.app.acfOptions
				const homepageDe = acfOptions.homepageDe
				const homepageEn = acfOptions.homepageEn
				const lang       = this.$route.params.lang
				let   pageSlug   = undefined


				if( lang === 'en' && homepageEn ){
					pageSlug = homepageEn.slug
				}else{
					pageSlug = homepageDe.slug
				}

				console.log('lang:', lang)
				console.log('acfOptions:', acfOptions)
				console.log('pageSlug:', pageSlug)

				// fetch news
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : pageSlug,
					},
					callback : (response) => {
						this.homepage = response.data.result[0]
					},
				})
			}
		},
		mounted() {
			this.fetchAll(()=>{
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isContentReady = true
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.HomeView {}
	.HomeView {	// layout
		&__newsPostsList {
			.grid;
			.grid--gap;
			.grid--cols-2;
		}
	}
	.HomeView {	// styling
		&__introText {

		}

		&__newsTitle {}


	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
