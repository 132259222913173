<template>
	<div class="App color color--textBlack background background--white font font--sans font--sizeDefault">

		<TsSplashScreen aria-hidden="true"></TsSplashScreen>

		<TsAppHeader></TsAppHeader>

		<MhRouterViewWrapper>
			<template v-slot:spinner>
				<div class="loadingSpinner"></div>
			</template>
		</MhRouterViewWrapper>

		<MhDelegateLinks></MhDelegateLinks>
		<MhDevInfos :showOnHosts="['localhost', 'transformingsolidarities-dev', 'dev.transformingsolidarities.net']"></MhDevInfos>

	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import resize from 'vue-resize-directive'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	//import MhStickyHeader from '@/components/MhStickyHeader/v1--dev/MhStickyHeader.vue'
	//import MainNav from '@/components/MainNav.vue'
	import TsSplashScreen from '@/molecules/TsSplashScreen.vue'
	import TsAppHeader from '@/molecules/TsAppHeader.vue'

	import MhDevInfos from '@/components/MhDevInfos/MhDevInfos.vue'
	import MhDevPanel from '@/components/MhDevPanel/v1/MhDevPanel.vue'
	import MhDelegateLinks from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDelegateLinks/v4/MhDelegateLinks.vue'
	import MhRouterViewWrapper from '@/components/MhRouterView/v3/MhRouterViewWrapper.vue'
	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'

	export default {
		name: 'App',
		components: {
			//MhStickyHeader,
			//MainNav,
			TsSplashScreen,
			TsAppHeader,
			MhRouterViewWrapper,
			MhSpinner,
			MhDevInfos,
			MhDevPanel,
			MhDelegateLinks,
		},
		directives: {
			resize,
		},
		mixins: [ RestHandler ],
		data() {
			return {
				isReady        : undefined,
				acfOptions     : undefined,
				mainCategories : undefined,
			}
		},
		computed: {
			lang(){
				//return this.$route.params.lang || this.$i18n.locale
				//console.log( this.$i18n.locale )
				return this.$i18n.locale
			},
		},
		methods: {
			setCssVars(){
				const htmlElm             = document.querySelector('html')
				const root                = document.documentElement
				      root.style.overflow = 'auto'
				      root.style.height   = '101vh'
				const scrollbarWidth      = window.innerWidth - root.offsetWidth
				      root.style.height   = ''
				      root.style.overflow = ''

				root.style.setProperty('--scrollbarWidth', scrollbarWidth + 'px')

				if(!scrollbarWidth) htmlElm.classList.add('hasMobileScrollbars')
				else htmlElm.classList.add('hasDesktopScrollbars')
			},
			getLinkWithoutHostname( url ){
				let theReturn = ''

				try {
					let _url = new URL( url )
					theReturn = _url.pathname
				}
				catch(e) {
					//
				}

				return theReturn
			},
			fetchAll( finalCallback ){
				let thingsToLoad = 2

				const runCallback = ()=>{
					--thingsToLoad

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				this.restHandler__fetch({ // acfOptions
					action : 'GET',
					route : '/wp-json/mh/v1/acfOptions',
					params : {},
					callback : (response) => {
						this.acfOptions = response.data.result

						runCallback()
					},
				})
				this.restHandler__fetch({ // taxonomyTerms
					action : 'GET',
					route : '/wp-json/mh/v1/taxonomyTerms',
					params : {
						slug : 'mainCategory'
					},
					callback : (response) => {
						this.mainCategories = response.data.result

						runCallback()
					},
				})
			},
			getMainCategoryById( termId, doLog = false ){
				const inputId = parseInt( termId )
				let mainCategory = this._.clone( this._.find( this.mainCategories, { term_id : inputId } ) )

				if( doLog ){
					console.log('termId:', termId)
					console.log('inputId:', inputId)
					console.log('mainCategory:', mainCategory)
				}

				// remove color emoticon from the beginning of the title
				//mainCategory.title = this._.get( mainCategory, 'name', '' ).substring( 3 )

				return mainCategory
			},
			changeLang( langSlug ){
				const currentLang = this.lang
				//console.log('langSlug:', langSlug)

				if( currentLang != langSlug ){
					this.$router.push({ params : { lang : langSlug } })
				}
			},
		},
		mounted(){
			this.setCssVars()

			const bodyElm = document.querySelector('body')

			bodyElm.classList.add('background')
			bodyElm.classList.add('background--white')
			bodyElm.classList.add('customScrollbars')

			this.fetchAll(()=>{
				console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isReady = true
			})

			//throw new Error

			/*
			this.$router.afterEach((to, from) => {
				console.group('this.$router.afterEach(to, from)')
				console.log('	from', from)
				console.log('	to  ', to)
				console.groupEnd()
			})
			*/

			// fetch example
			/*
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					this.acfOptions = response.data.result

					// hier wird aus allen introImages eins zufällig ausgewählt
					this.acfOptions.introImage = this._.sample( this.acfOptions.introImages )

					// um das layout handeln zu können sind die beiden
					// ersten menüpunkte, news + info, fest ins system integriert
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "Info",
						title__en: "Info",
						type: "toggleSecondRow",
						page: false
					})
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "News",
						title__en: "News",
						type: "homeView",
						page: false
					})

					this.$set(this.acfOptions, 'translations2', this.translations )
				},
			})
			*/

			// after route change: scroll App__body to top
			/*
				const AppBody = document.querySelector('.App__body')
				this.$router.afterEach( (to, from) => {
					AppBody.scrollTo(0, 0)
				})
			*/

			// EventBus example
			/*
				EventBus.$on('AppHeaderResized', elmHeight => {
					console.log( elmHeight )
				})
			*/
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import "@/less/atoms.less";

	:root { // set vars
		--app-header-height : 50px;
		--app-content-hSpace : 15px;
		--app-content-gridGap : 15px;

		@media @mq[md] {
			--app-content-hSpace : 13px;
		}
	}

	//html { background-color: red; }
	//.view { background-color: fade( green, 50 ); overflow: hidden; }

	html {
		//position: relative;
		font-size: 16px;
		line-height: 1.2em;
		// wieder raus, lässt den grünen Hintergrund auf der Startseite durchblitzen
		//background-color: @swatches[green];
	}
	strong, b {
		.font--bold;
	}
	button {
		cursor: pointer;
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	a {
		cursor: pointer;
		color: blue;
		text-decoration: none;

		.richText & {
			transition: all 0.1s ease;
			color: inherit;
			opacity: 0.5;
			text-decoration: none;
			//border-bottom: 0.1em solid;
		}
		.richText &:hover {
			opacity: 1;
		}
	}
	pre {
		@color: black;

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;

		//margin-bottom: 0.5em;
		//&:first-of-type { margin-top: 1em; }
		//&:last-of-type { margin-bottom: 1em; }
		//&:first-child { margin-top: 0em; }

		//color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;

		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;
		//display: none;

		// label
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(name);
			font-family: inherit;
		}

		&[maxheight] {
			max-height: 300px;
			overflow: auto;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}
	ul, ol {
		margin-left: 1rem;
	}

	//*:focus { outline: 3px solid red !important; }
	.font--sizeBig {			// 60/60
		font-size: 60px;
		line-height: 1em;
		//color: red !important;

		@media @mq[md] { font-size: 50px; letter-spacing: -0.01em; }
	}
	.font--sizeLarge {			// 30/30
		font-size: 30px;
		line-height: 1em;
		//color: green !important;
	}
	.font--sizeMedium {			// 25/30
		font-size: 25px;
		line-height: 1.3em;
		//color: orange !important;

		@media @mq[md] { font-size: 20px; }
	}
	.font--sizeDefault {		// 20/25
		font-size: 20px;
		line-height: 1.25em;
		//color: pink !important;

		@media @mq[md] { font-size: 14px; }
		@media @mq[sm] {}
		@media @mq[xs] {}
	}
	.font--sizeSmall {			// 16/19
		font-size: 16px;
		line-height: 19px;

		@media @mq[md] {}
		@media @mq[sm] {}
		@media @mq[xs] {}
	}
	.font--sizeMini {			// 14/14	• TsNewsPostTeaser + TsAdjacentLinks
		font-size: 14px;
		line-height: 14px;
	}
	.font--sizeTiny {			// 12/12	• TsNewsPostTeaser
		font-size: 12px;
		line-height: 12px;
	}
	[data-dev-name] { // debug outlining
		[showBorders1] & {
			outline: 1px solid fade(red, 60);
			//background-color: fade(red, 0);
			.outline( red );
			.label( attr(data-dev-name), fade(red, 60), black );
		}
	}
	.contentModule { // general settings
		position: relative;

		&__inner {
			//padding: 1em; // dev only

			position: relative;
			margin: 0 auto;
		}
	}
	.view { // debug
		[showBorders2] & {
			.view__panel--left { background-color: fade( green, 20 ); }
			.view__panel--right { background-color: fade( blue, 20 );}
		}
	}
	.view { // grid
		position: relative;
		display: flex;
		//flex-direction: column;

		&__inner {
			.grid;
			.grid--cols-4;

			flex-grow: 1;
		}
		.view__panel{
    		display: flex;
			flex-direction: column;
			@media @mq[dt] { min-height: calc( 100vh - var(--app-header-height) ); }
		}
		// pin left column with introText or gallery
		.view__panel--left {
			@media @mq[dt] { position: sticky; top: var(--app-header-height); }
			@media @mq[dt] { height: calc( 100vh - var(--app-header-height) ); }
			grid-column: span 2; @media @mq[md] { grid-column: span 4; }
		}
		.view__panel--right {
			grid-column: span 2; @media @mq[md] { grid-column: span 4; }
			padding-bottom: 1em;
		}
		.view__panel--both {
			width: 100%;
			grid-column: span 4;
		}
	}
	.hyphenate { // debug
		//color: red;
	}
	.richText {
		p + p {
			margin-top: 0.5em;
		}
		ul, ol { margin-left: 1.25em; }
		li { padding-left: 0.25em; }
	}
	.loadingSpinner {
		background-image: url('./assets/images/icon--loadingSpinner.svg');
		background-repeat: no-repeat;
		background-size: contain;
		animation-name: spin;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		height: 10em; width: 10em;

		@keyframes spin {
			from {
				transform:rotate(0deg);
			}
			to {
				transform:rotate(360deg);
			}
		}
	}
	.customScrollbars {
		.hasDesktopScrollbars & {
			/* width */
			&::-webkit-scrollbar {
				width: 12px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				//box-shadow: inset 0 0 5px grey;
				//border-radius: 10px;
				background-color: white;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				transition: all 0.35s ease;
				background: fade( black, 25 );
				border: 3px solid var(--backgroundColor);
				border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb:hover {
				background: fade( black, 100 );
			}
		}
	}
	.scroller {
		overflow: hidden;
		overflow-y: auto;
	}
	.gridDebug {
		[showborders1] & { visibility: visible; }

		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		min-height: 100vh;
		z-index: 100;
		pointer-events: none;
		outline: 1px solid cyan;
		visibility: hidden;

		& > * {
			background-color: fade( cyan, 50 );
			outline: 1px solid cyan;
		}
	}
	.line {
		display: block;
		border: none;
		border-top: 2px solid currentColor;

		@media @mq[md] { border-width: 1.5px ; }
	}
	.coloredDot {
		display: inline-block;
		height: 1em;
		width: 1em;
		background-color: currentColor;
		border-radius: 50%;

		&--size-big {
			height: 1.9em; width: 1.9em;
			@media @mq[md] { height: 1.5em; width: 1.5em; }
		}

		& + & { margin-left: 0.25em; }
	}
	.link {
		transition: all 0.1s ease;
		opacity: 0.65;
		color: inherit;
		text-decoration: none;

		&:hover {
			opacity: 1;
		}
		&--underline {
			border-bottom: 2px solid;

			&:hover {
				//border-bottom-width: 2px;
				border-bottom-style: solid;
			}
		}
		&--color {}
	}
	.hideIfEmpty {
		&:empty { display: none; }
	}
	.hSpace--content {
		padding-left: var(--app-content-hSpace);
		padding-right: var(--app-content-hSpace);
	}
	.devLangToggle {
		position: fixed;
		left: 1em;
		bottom: 2em;
		z-index: 10;
		background-color: fade(yellow, 50);
	}

	// prevent layout jumps after content is loaded
	.App {
		min-height: calc( 100vh + 1px );
	}

	// make sure that the viewport is filled
	.App,
	.MhRouterViewWrapper,
	.MhRouterView {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.view__inner {
		min-height: 100%;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
