<!--

	Diese Componente kümmert sich darum, dass interne Links aus
	HTML-Richtext zum Router delegiert werden.
	Am besten in App.vue integrieren.

	Taken from https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
	and improved

	Just add to App.vue
		<MhDelegateLinks></MhDelegateLinks>

	2021-10-02	bugfix: links mit hash #405 waren preventDefault, jetzt werden sie durchgelassen
	2021-10-02	improvement: added prop doLog

	2021-09-26	bugfix: console.group was missing
	2021-08-30	bugfix: download-links wurden nicht behandelt. jetzt werden alle links zu "wp-content/uploads" durchgereicht.
	2021-08-07	bugfix: if else else abfrage hat falsch geendet wenn <a target></a> mit attr target aber ohne füllung
	2021-06-17	improvement: removed sill logging
	2021-06-17	improvement: isDownloadURL prüft jetzt ob die url ein string ist
	2021-06-17	improvement: exclude routerLink via class-string contains "routerLink"
	2021-06-16	bufix: doLog im clickHandler hat in bestimmten Fällen das groupEnd() weggelassen
	2021-06-13	improvement: bei den hosts in env.js wird jetzt nur noch der anfang des strings verglichen, damit kann z.B. getestet werden ob die adresse mit 192 beginnt. hilfreich für's lokale testen

	2021-05-31	improvement: changed logging
	2020-12-04	refactor: works now with config/env.js
	2019-06-06	init

-->

<template>
	<div class="MhDelegateLinks"></div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import envConfig from '@/config/env.js'

	export default {
		name: 'MhDelegateLinks',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			doLog: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			internalHosts(){
				return this.getInternalHosts()
			},
			currentHost(){
				return window.location.hostname
			},
		},
		methods: {
			getInternalHosts(){	// taken from envConfig
				let internalHosts = []

				this._.forEach( envConfig, (env) => {
					internalHosts.push( env.hostname )
				})

				return internalHosts
			},
			isURL( str ) {
		        /*
				var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		        var url = new RegExp(urlRegex, 'i');
		        return str.length < 2083 && url.test(str);
				*/
				try {
				  new URL( str );
				} catch (_) {
				  return false;
				}

				return true;
		    },
			is404( url ) { // resolve route. will it lead to NotFoundView?
				let resolved = this.$router.resolve( url )

				if(resolved.route.name == 'NotFoundView'){
					return true
				}else{
					return false
				}
			},
			isDownloadURL( url ) { // checks if url goes to /wp-content/uploads/
				let is = false

				// just exclude every link that goes to wp upload dir
				is = this._.isString( url ) ? url.indexOf("/wp-content/uploads/") != -1 : false

				return is
			},
			clickHandler( event, doLog = false ){
				// makes doLog available via prop
				if( this.doLog ) doLog = true

				// ensure we use the link, in case the click has been received by a subelement
				let { target } = event
				while (target && target.tagName !== 'A') target = target.parentNode

				const href          = target && target.href ? target.href : false
				const hash      	= this.isURL( href ) ? new URL(href).hash : ''
				const hrefHost      = this.isURL( href ) ? new URL(href).hostname : false
				//const hrefHost      = new URL(href).hostname
				const routeTo       = this.isURL( href ) ? new URL(href).pathname + new URL(href).search + hash : false
				const isDownloadURL = this.isDownloadURL( href )
				const is404         = this.is404( routeTo )
				const isRouterLink  = target && target.getAttribute('class') && target.getAttribute('class').indexOf('routerLink') !== -1
				let theReturn       = undefined

				//const isInternal    = this._.includes( this.internalHosts, hrefHost )
				let isInternal = false
				this._.forEach( this.internalHosts, (validHost)=>{
					if( this._.startsWith( hrefHost, validHost ) ){
						//console.log('1', validHost, hrefHost)
						isInternal = true
					}else{
						//console.log('2', validHost, hrefHost)
					}
				})

				if( doLog ){
					console.groupCollapsed( this.$options.name, '• clickHandler()')
					console.log('           target:', target)
					console.log('     isRouterLink:', isRouterLink)
					console.log('             href:', href)
					console.log('             hash:', hash)
					console.log('    internalHosts:', this.internalHosts)
					console.log('         hrefHost:', hrefHost)
					console.log('       isInternal:', isInternal)
					console.log('            is404:', is404)
					console.log('          routeTo:', routeTo)
					console.log('    isDownloadURL:', isDownloadURL)
				}

				// exclude links without href and router-links
				if( !href || isRouterLink ){
					if( doLog ) console.groupEnd()
					return
				}

				if( isDownloadURL ) {
					if( doLog ) console.groupEnd()
					return
				}

				// handle only internal links that have a valid route
				if( target && isInternal && !is404 ){

					// some sanity checks taken from vue-router:
					// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
					const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event

					// don't handle with control keys
					if( metaKey || altKey || ctrlKey || shiftKey ){
						if( doLog ) console.log('1')
						theReturn = false
					}
					// don't handle when preventDefault called
					/*
					else if( defaultPrevented ){
						if( doLog ) console.log('2')
						theReturn = false
					}
					*/
					// don't handle right clicks
					else if( button !== undefined && button !== 0 ){
						if( doLog ) console.log('3')
						theReturn = false
					}
					// don't handle if `target="_blank"`
					else if( target && target.getAttribute('target') === '_blank' ){
						const targetAttr = target.getAttribute('target')
						const doIt = ( /\b_blank\b/i.test( targetAttr ) )

						if( doLog ) console.log('4', targetAttr, doIt)

						theReturn = doIt
					}
					// dont handle links with hash
					else if( hash ){
						if( doLog ) console.log('5 is link with hash > return it', hash)
						theReturn = true
					}
					else{
						if( doLog ) console.log('6')
						if( doLog ) console.log('window.location.pathname:', window.location.pathname)
						if( doLog ) console.log('routeTo:', routeTo)

						theReturn = false
					}
					// don't handle same page links/anchors
					//const url = new URL( target.href )
					//const to  = routeTo //url.pathname
					if( !theReturn ){
						if( doLog ) console.log('7')
						if( doLog ) console.log('	           >>>', routeTo)
						if( doLog ) console.log( this.$route )

						//this.$router.replace('/')
						if( window.location.pathname !== routeTo ) this.$router.push( routeTo )

						event.preventDefault()
						//event.stopPropagation()
					}else{
						if( doLog ) console.log('8')
					}
				}else{
					if( doLog ) console.log('	           >>> no route to push')
				}

				if( doLog ) console.groupEnd()

				//if( theReturn ) return theReturn

				//event.preventDefault()
			},
		},
		created() {
			window.addEventListener('click', this.clickHandler)
		},
		mounted() {},
		beforeDestroy() {
			window.removeEventListener('click', this.clickHandler)
		},
	}
</script>

<style lang="less">
	.MhDelegateLinks {}
</style>
