<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsMainCategoriesBar></TsMainCategoriesBar>

	2019-06-08	init

-->

<template>
	<div class="TsMainCategoriesBar" :class="elmClasses" v-if="mainCategories" data-dev-name="TsMainCategoriesBar">
		<div class="TsMainCategoriesBar__inner">
			<div class="TsMainCategoriesBar__dots">
				<span class="TsMainCategoriesBar__dot coloredDot"
					:class="'coloredDot--size-' + dotSize"
					:style="{ color : _.get( app.getMainCategoryById( mainCategoryId ), 'acf.color' ) }"
					v-for="mainCategoryId in mainCategories"
					:key="'cD' + mainCategoryId"
				></span>
			</div>
			<div class="TsMainCategoriesBar__titles">
				<div>
					{{titlesString}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'TsMainCategoriesBar',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			mainCategories: {
				type     : [Array, Boolean],
				default  : false,
			},
			dotSize: {
				type     : [String, Boolean],
				default  : 'default',
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			titlesString(){
				let parts = []
				let string = undefined
				const lang = this.app.lang

				this._.forEach( this.mainCategories, (mainCategoryId)=>{
					const title = this._.get( this.app.getMainCategoryById( mainCategoryId ), 'acf.' + lang )

					parts.push( title )
					parts.push( ' / ' )
				})

				parts.splice( parts.length-1, 1 )

				string = parts.join('')

				/*
				<span class="TsMainCategoriesBar__title"
					v-for="mainCategoryId in mainCategories"
					:key="'t' + mainCategoryId"
					v-html="_.get( app.getMainCategoryById( mainCategoryId ), 'acf.de' )"
				></span>
				*/

				return string
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.TsMainCategoriesBar { // debug
		[showBorders2] & {}
	}
	.TsMainCategoriesBar { // layout
		&__inner {
			display: flex;
		}
		&__dots, &__titles {
			display: flex;
			align-items: center;
		}
		&__titles {
			flex-grow: 1;
		}
	}
	.TsMainCategoriesBar { // styling
		&__titles { // text-overflow with ellipsis
			min-width: 0; // needed to get the ellipsis work in flex parent

			& > * {
				//width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&__dots + &__titles {	// space between dots and titles
			padding-left: 0.75em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
