<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsAppHeader></TsAppHeader>

	2019-06-08	init

-->

<template>
	<header class="TsAppHeader background background--green" :class="elmClasses" data-dev-name="TsAppHeader">
		<!-- header bar with logo and buttons -->
		<div class="TsAppHeader__inner hSpace hSpace--content">
			<!-- logo -->
			<h6 class="TsAppHeader__logo font font--sans font--bold font--sizeMedium">
				<!--
				<router-link class="TsAppHeader__logoLink" :to="{ name : 'HomeView', lang : app.lang }">
				-->
				<router-link class="TsAppHeader__logoLink" :to="'/' + app.lang + '/'">
					Transforming Solidarities
				</router-link>
			</h6>
			<div class="TsAppHeader__searchBar" :class="searchBar.isOpen ? 'TsAppHeader__searchBar--isOpen' : 'TsAppHeader__searchBar--isClosed'">
				<button class="TsAppHeader__searchBarOpenBtn reset reset--button font font--sizeMedium"
					role="button"
					@click="searchBar.isOpen = true; $refs.searchBarInput.focus()">
					Search ...
				</button>
				<input class="TsAppHeader__searchBarInput reset reset--input font font--mono"
					type="text"
					ref="searchBarInput"
					v-model="searchBar.query"
					v-on:keyup.enter="doSearch"
				/>
				<button class="TsAppHeader__searchBarCloseBtn reset reset--button font font--sizeMedium"
					role="button"
					@click="searchBar.isOpen = false; searchBar.query = ''">
					Close
				</button>
			</div>
			<a class="TsAppHeader__langToggleBtn reset reset--button font font--sans font--semiBold font--sizeMedium"
				v-if="!$root.isBelowDt"
				:href="toggleLangLink">
				<span v-if="app.lang === 'de'">EN</span>
				<span v-else>DE</span>
			</a>
			<button class="TsAppHeader__menuOpenBtn reset reset--button font font--sizeMedium"
				role="button"
				@click="isVisibleMenu = !isVisibleMenu">
				Open navigation menu
			</button>
		</div>

		<!-- slide out menu -->
		<nav class="TsAppHeader__menuWrapper" :aria-hidden="!isVisibleMenu" v-if="menu">
			<div class="TsAppHeader__menuInner background background--yellow"
				:class="{'TsAppHeader__menuInner--isVisible' : isVisibleMenu }" ref="menuInner">
				<div class="TsAppHeader__menuHeader hSpace hSpace--content">
					<button class="TsAppHeader__menuCloseBtn reset reset--button font font--sizeMedium" role="button" @click="isVisibleMenu = !isVisibleMenu">
						Close navigation menu
					</button>
				</div>
				<div class="TsAppHeader__menuBody scroller hideScrollbars hSpace hSpace--content">

					<div class="TsAppHeader__menuItem" :class="'TsAppHeader__menuItem--' + menuItem.type"
						 v-for="(menuItem, index) in menu" :key="index">
						<!-- single link -->
						<template v-if="menuItem.type === 'single'">
							<a 	class="TsAppHeader__menuItemLink font font--sans font--bold"
								:href="menuItem.singleLink.url"
								:target="menuItem.singleLink.target != '0' ? menuItem.singleLink.target : ''"
								@click="isVisibleMenu = false"
							>{{menuItem.singleLink.title}}</a>
						</template>
						<!-- group of links -->
						<template v-if="menuItem.type === 'group'">
							<h6 class="TsAppHeader__menuItemGroupTitle font font--mono font--sizeDefault">{{menuItem.linkGroup.title}}</h6>
							<ol class="block reset reset--list">
								<li v-for="(linkItem, lIndex) in menuItem.linkGroup.links" :key="'l'+lIndex">
									<a	class="TsAppHeader__menuItemLink font font--sans font--bold"
										:href="linkItem.link.url"
										:target="linkItem.link.target != '0' ? linkItem.link.target : ''"
										@click="isVisibleMenu = false"
									>{{linkItem.link.title}}</a>
								</li>
							</ol>
						</template>
						<!-- spacer link -->
						<template v-if="menuItem.type === 'spacer'">
							<br/>
						</template>
					</div>

					<div class="TsAppHeader__menuItem TsAppHeader__menuItem--langToggle" v-if="$root.isBelowDt">
						<a class="TsAppHeader__menuItemLink font font--sans font--bold"
							:href="toggleLangLink" @click="isVisibleMenu = false">
							<span v-if="app.lang === 'de'">EN</span>
							<span v-if="app.lang === 'en'">DE</span>
						</a>
					</div>

					<!--
					<pre name="menu">{{menu}}</pre>
					-->
				</div>
			</div>
		</nav>

		<!-- enlarge bg for nicher overscroll -->
		<div class="TsAppHeader__enlargeBg"></div>
	</header>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'TsAppHeader',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			myProp: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {
				isVisibleMenu : false,
				currentPost : undefined,
				searchBar : {
					isOpen : false,
					query : '',
				},
			}
		},
		watch: {
			'$route.name': { // toggle searchBar according to SearchView or not
				handler: function( to, from, doLog = false ) {
					if( doLog ){
						console.group( this.$options.name, '• watch: $route.name')
						console.log('from:', from)
						console.log('to:', to)
						console.groupEnd()
					}

					if( 'SearchView' === to ){
						this.searchBar.isOpen = true
						this.searchBar.query = this._.unescape( this.$route.params.query )
					}
					else{
						this.searchBar.isOpen = false
						this.searchBar.query = ''
					}
				},
				immediate : true,
				deep: true,
			},
			'isVisibleMenu': { // safari needs this css change to enable pointer-events on this elm
				handler: function( to, from, doLog = true ) {
					const menuInnerElm = this.$refs.menuInner
					if( doLog ){
						console.group( this.$options.name, '• watch: isVisibleMenu')
						console.log('from:', from)
						console.log('to:', to)
						console.groupEnd()
					}

					if( menuInnerElm ){
						if( to ) menuInnerElm.style.opacity = 1
						else menuInnerElm.style.opacity = undefined
					}
				},
				immediate : false,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.searchBar.isOpen ) classes.push( this.$options.name + '--hasOpenSearchBar')
				else classes.push( this.$options.name + '--hasClosedSearchBar')

				return classes
			},
			menu(){
				const lang = this.app.lang
				let menu   = undefined

				if( 'de' === lang ){
					menu = this._.get( this.app, 'acfOptions.menuElements' )
				}
				else{
					menu = this._.get( this.app, 'acfOptions.menuElements__en' )
				}

				return menu
			},
			toggleLangLink(){ // choose current translation or goto home
				const lang     = this.app.lang
				const homeLink = this.app.lang === 'de' ? '/en/' : '/de/'
				let   link     = ''

				if( 'de' === lang ){
					link = this._.get( this.currentPost, 'polylang.translations.en', link )
				}
				if( 'en' === lang ){
					link = this._.get( this.currentPost, 'polylang.translations.de', link )
				}

				link = link.replace( this._.get( this.app.acfOptions, 'homepageDe.slug', '•••' ) + '/', '' )
				link = link.replace( this._.get( this.app.acfOptions, 'homepageEn.slug', '•••' ) + '/', '' )

				//return this.app.getLinkWithoutHostname( link )
				return link ? this.app.getLinkWithoutHostname( link ) : homeLink
			},
		},
		methods: {
			doToggleMenu( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• doToggleMenu()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			doSearch( doLog = true ){
				const queryString = this._.trim( this.searchBar.query )

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• doSearch()' )
					console.log('queryString:', queryString)
					console.groupEnd()
				}


				if( queryString ) this.$router.push({ name: 'SearchView', params: { query: queryString } })

				//throw new Error
			},
		},
		created(){},
		mounted(){
			EventBus.$on('PageView • content ready', (post)=>{
				this.currentPost = post
			})
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsAppHeader { // debug
		[showBorders2] & {
			&__inner > * {
				outline: 1px solid red;
				outline: 1px solid red;
			}
			&__menuInner > * {
				outline: 1px solid red;
				background-color: fade( red, 25 );
			}
		}
		[showBorders3] & {
			&__searchBar > * { outline: 1px solid red; }
			//&__searchBar--isOpen { background-color: fade( green, 20 ); }
			//&__searchBar--isClosed { background-color: fade( red, 20 ); }
		}
	}
	.TsAppHeader { // vars
		--app-header-height: 50px;
	}
	.TsAppHeader { // layout
		position: sticky !important; // overwrite position: relative; from debug outlining
		top: 0;
		height: var(--app-header-height);
		display: flex;
		z-index: 10;

		&__inner {
			display: flex;
			flex-grow: 1;
		}
		&__inner > * {
			height: 100%;
			display: flex;
			align-items: center;
		}

		& &__logo {
			height: 100%;
			flex-grow: 1;
			min-width: calc( 50% + ( var(--app-content-gridGap) * 0 ) );
			//min-width: 50%;
		}

		& &__searchBar {
			display: flex;
			flex-grow: 1;
			justify-content: flex-end;
			align-items: center;
			padding: 0.25em 0;
			overflow: hidden;

			&Input { height: 100%; padding: 0 0.25em; }
			&OpenBtn { display: flex; height: 100%; width: 1.5em; flex-shrink: 0; }
			&CloseBtn { display: flex; height: 100%; width: 1.5em; flex-shrink: 0; }
		}
		&--hasOpenSearchBar &__searchBar { margin-right: 10px; }
		&--hasClosedSearchBar &__searchBar &__searchBarInput { width: 0%; padding: 0; }
		&--hasOpenSearchBar &__searchBar &__searchBarInput { width: 100%; }
		&--hasClosedSearchBar &__searchBar &__searchBarCloseBtn { width: 0%; }

		& &__langToggleBtn {
			width: 2.5em;
		}
		& &__menuOpenBtn,
		& &__menuCloseBtn {
			// wofür war das min-width gleich gut?
			// im safari wurde der btn breiter dargestellt,
			// deswegen bin ich zu width gewechselt
			//min-width: 1.75em;
			width: 1.75em;
		}

		@media @mq[md] {
			& &__menuOpenBtn,
			& &__menuCloseBtn {
				margin-left: 0.25em;
			}
		}
		@media @mq[sm] {
			&--hasOpenSearchBar &__logo {
				overflow: hidden;
				max-width: 0; //margin-left: calc( -50vw );
				min-width: 0; //margin-left: calc( -50vw );
			}
		}
	}
	.TsAppHeader { // styling
		& &__logo {
			&Link {
				color: inherit;
				/*
				padding-right: 4em;
				color: transparent;

				background-image: url('./../assets/images/logo.svg');
				background-repeat: no-repeat;
				background-position: left center;
				background-size: contain;
				*/
			}
			@media @mq[md] {
				font-size: 18px;
				line-height: 20px;
			}
		}
		& &__searchBar {
			border-radius: 10px;
			transition: all 0.2s ease;
			//font-weight: bold;

			&OpenBtn, &CloseBtn {
				//overflow: hidden;
				text-indent: -100em;
				transition: background 0.2s ease;

				background-repeat: no-repeat;
				background-position: center;
			}
			&OpenBtn {
				background-image: url('./../assets/images/icon--searchLense.svg');
			}
			&CloseBtn {
				background-image: url('./../assets/images/icon--closeCross.svg');
				background-size: 50%;
			}
			&Input {
				transition: all 0.2s ease;
			}
		}
		&--hasOpenSearchBar &__searchBarOpenBtn,
		&--hasOpenSearchBar &__searchBarInput,
		&--hasOpenSearchBar &__searchBarCloseBtn {
			background-color: fade( black, 8 );
		}

		& &__langToggleBtn {
			span {
				width: 100%;
				display: inline-block;
				font-size: 1.35em;
				text-align: center;
			}
		}
		& &__menuOpenBtn,
		& &__menuCloseBtn {
			overflow: hidden;
			text-indent: -100em;
			flex-shrink: 0;

			background-repeat: no-repeat;
			background-position: right center;
		}
		& &__menuOpenBtn {
			background-image: url('./../assets/images/icon--hamburger.svg');
		}
		& &__menuCloseBtn {
			background-image: url('./../assets/images/icon--closeCross.svg');
		}

		@media @mq[sm] {
			&__searchBarInput {
				font-size: 20px !important;
			}
		}
	}

	.TsAppHeader__menu {
		&Wrapper {
			position: fixed;
			top: 0; left: 0; right: 0; bottom: 0;
			pointer-events: none;
		}
		&Inner {
			position: absolute;
			top: 0; left: 50%;
			height: 100%; width: 50%;
			display: flex;
			flex-direction: column;

			transition: all 0.35s ease;
			transform: translateY(-100%)
		}
		&Inner--isVisible {
			transform: translateY(0%);
			pointer-events: auto;
		}

		&Header {
			pointer-events: none;
			height: var(--app-header-height);
			display: flex;
			justify-content: flex-end;
			flex-shrink: 0;
			z-index: 2;

			& > * { pointer-events: auto; }
		}
		&Body {
			margin-top: calc( 0px - var(--app-header-height) );
			flex-grow: 1;
			padding-top: 1em;

			& > * { pointer-events: auto; }
		}

		&Item--group { padding-top: 2.5rem; }
		&Item--group + &Item--single { padding-top: 2.5rem; }
		&Item--spacer { padding-top: 1.5rem; }

		&ItemLink { color: inherit; transition: all 0.1s ease; font-size: 3rem; line-height: 0.9em; }
		&ItemLink:hover { color: @swatches[blue]; }

		&ItemGroupTitle { padding-bottom: 0.25em;  }

		@media @mq[md] {
			&Inner {
				left: 0;
				width: 100%;
			}

			&ItemLink { font-size: 34px; }
			&Item--group { padding-top: 1.75rem; }
			&Item--group + &Item--single { padding-top: 1.75rem; }
			&Item--spacer { padding-top: 0.75rem; }
			&Item--langToggle { padding-top: 1.75rem; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
