<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsPostHeader></TsPostHeader>

	2019-06-08	init

-->

<template>
	<div class="TsPostHeader contentModule hSpace hSpace--content" :class="elmClasses" data-dev-name="TsPostHeader">
		<div class="TsPostHeader__inner contentModule__inner">
			<TsMainCategoriesBar
				class="font font--sans font--bold"
				:mainCategories="mainCategories"
				:dotSize="'big'"
			></TsMainCategoriesBar>

			<span class="TsPostHeader__line line" v-if="mainCategories"></span>

			<h4 class="TsPostHeader__overTitle hideIfEmpty font font--mono" v-html="overTitle"></h4>
			<h1 class="TsPostHeader__mainTitle hyphenate font hyphenate font--sans font--bold font--sizeBig" v-html="mainTitle"></h1>
		</div>
		<!--
		<pre name="$props">{{$props}}</pre>
		<pre name="$attrs">{{$attrs}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import TsMainCategoriesBar from '@/molecules/TsMainCategoriesBar.vue'

	export default {
		name: 'TsPostHeader',
		components: {
			TsMainCategoriesBar,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			mainCategories: {
				type     : [Array, Boolean],
				default  : false,
			},
			overTitle: {
				type     : [String],
				default  : '',
			},
			mainTitle: {
				type     : [String, Boolean],
				default  : false,
			},
			postType: {
				type     : [String, Boolean],
				default  : 'page',
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( !this.mainCategories ) classes.push( this.$options.name + '--withoutMainCategories')
				else classes.push( this.$options.name + '--withMainCategories')

				classes.push( this.$options.name + '--postType-' + this.postType )

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.TsPostHeader { // debug
		//background-color: fade( red, 30 );

		[showBorders3] &__inner > * { outline: 1px solid red; }
	}
	.TsPostHeader { // layout
		//
	}
	.TsPostHeader { // styling
		padding-bottom: 57px;

		&--postType-page { // default bottom space
			padding-bottom: 30px;

			@media @mq[md] { padding-bottom: 20px; }
		}

		&--postType-post,
		&--postType-resources_post,
		&--postType-teaching_post,
		&--postType-activity_post {
			@media @mq[md] { padding-bottom: 11px; }
		}

		&--withMainCategories {
			padding-top: 15px;

			@media @mq[md] { padding-top: 12px; }
		}
		&--withoutMainCategories {
			padding-top: 30px;

			@media @mq[md] { padding-top: 10px; }
		}

		.TsMainCategoriesBar {
			padding-bottom: 0.6em;
			font-size: 25px;
			line-height: 1.3em;

			@media @mq[md] { font-size: 20px; }
		}

		&__line {
			padding-bottom: 25px;

			@media @mq[md] { padding-bottom: 11px; }
		}
		&__overTitle {
			padding-bottom: 0.8em;

			@media @mq[md] {
				padding-bottom: 0.35em;
				font-size: 16px; line-height: 1.2em;
			}
		}
		&--postType-post &__mainTitle,
		&--postType-resources_post &__mainTitle,
		&--postType-teaching_post &__mainTitle,
		&--postType-activity_post &__mainTitle {
			@media @mq[md] { font-size: 25px; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
