<template>
	<div class="MhDevPanel">
		<div class="MhDevPanel__inner">

			<!-- Base Links -->
			<div class="MhDevPanel__blocks">
				<div class="MhDevPanel__block">
					<div class="MhDevPanel__blockHeader">
						Links
					</div>
					<div class="MhDevPanel__blockBody">
						<router-link class="MhDevPanel__blockLink" :to="'/'">Home</router-link>
						<router-link class="MhDevPanel__blockLink" :to="'/DevView/'">DevView</router-link>
					</div>
				</div>
			</div>

			<!-- App Routes -->
			<div class="MhDevPanel__blocks">
				<div class="MhDevPanel__block">
					<div class="MhDevPanel__blockHeader">
						Routes
					</div>
					<div class="MhDevPanel__blockBody">
						<div class="MhDevPanel__blockLink" v-for="(route, r) in $router.options.routes" :key="'r'+r">
							{{route.name}} • {{route.path}}
						</div>
					</div>
				</div>
			</div>

			<!-- Wordpress Posts -->
			<div class="MhDevPanel__blocks">
				<!--
				<pre>{{types}}</pre>
				-->
				<div class="MhDevPanel__block" v-for="(type, i) in types" :key="i">
					<div class="MhDevPanel__blockHeader">
						{{type.name}} • {{ type.slug}} • {{type.items.length}} items
					</div>
					<div class="MhDevPanel__blockBody">
						<a	class="MhDevPanel__blockLink"
							v-for="(type, j) in type.items"
							:key="'j'+j"
							:title="getLinkWithoutHostname( type.link )"
							:href="getLinkWithoutHostname( type.link )"
						>{{type.title.rendered}}</a>
						<br v-if="!type.items.length" />
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhDevPanel',
		components: {},
		props: {},
		data(){
			return {
				types : {},
			}
		},
		watch: {},
		computed: {},
		methods: {
			async fetchTypes(){
				let response = await fetch('/wp-json/wp/v2/types')
				let headers  = response.headers
				let parsed = await response.json()

				// walk the fetched types
				// filter out "wp_*"
				// fetch every type
				this._.forEach( parsed, (v, k)=>{
					if( k.indexOf('wp_') === -1 ){
						//console.log(`${k}`)
						v.items = []

						this.$set( this.types, k, v ) // $set is needed, vue wont update without
						this.fetchType( k )
					}
				})
			},
			async fetchType( typeKey ){
				let type = this.types[typeKey]

				//console.log('typeKey:', typeKey)
				//console.log('type:', type)

				let response = await fetch('/wp-json/wp/v2/' + type.rest_base )
				let headers  = response.headers
				let parsed = await response.json()

				this.types[typeKey].items = parsed

				//console.log('parsed:', parsed)
				/*
				*/
			},
			getLinkWithoutHostname( url ){
				let theReturn = ''

				try {
					let _url = new URL( url )
					theReturn = _url.pathname
				}
				catch(e) {
					//
				}

				return theReturn
			},
		},
		created(){},
		mounted(){
			this.fetchTypes()

			const $router = this.$router
			//console.log( $router )
		},
	}
</script>

<style lang="less">
	//@import "../less/vars.less";
	//@import "../less/mixins.less";
	//@import (reference) "../less/atoms.less";

	.MhDevPanel {
		position: relative;
		padding: 0.75rem;
		padding-bottom: 2rem;
		//margin-bottom: 3px;
		font-size: 13px;
		line-height: 1.3em;
		background-color: lighten( cyan, 30 );
		color: black;

		&__inner {
			//padding: 1rem;
		}

		.routerLink--exactActive {
			//font-weight: bold;
			color: red;
		}

		&__blocks {
			display: grid;
			grid-gap: 0.5em;
			grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

			& + & { margin-top: 0.5em; }
		}
		&__block {
			border: 1px solid fade( black, 25 );
			display: flex;
			flex-direction: column;

			&Header {
				padding: 0.25rem;
				background-color: fade(white, 25);
				background-color: fade(black, 10);
				border-bottom: 1px solid fade( black, 25 );
				font-weight: bold;
			}
			&Body {
				flex-grow: 1;
				padding: 0.25rem;
				background-color: fade(black, 20);
			}
			&Link {
				display: block;
			}
		}
	}
</style>
