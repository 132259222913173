export default {
	de : {
		placeholder : {},
		nav : {},
		title: {
			searchResultsFor : 'Such&shy;ergebnisse für',
			error404 : 'Fehler 404<br/> Es wurde kein Inhalt gefunden.',
		},
		button: {
			backToHome : 'zurück zur Startseite',
			backToRessources: 'zurück zu Publikationen',
			backToTeaching: 'zurück zu Teaching',
			backToActivities: 'zurück zu Aktivitäten',
			readMore: 'mehr lesen',
			readLess: 'weniger lesen',
		},
		message: {
			nothingFound : 'Nichts gefunden'
		},
		searchResults: {
			news: 'News',
			activities: 'Aktivitäten',
			pages: 'Seiten',
			team: 'Team',
			resources: 'Publikationen',
			teaching: 'Teaching',
		},
	},
	en : {
		placeholder: {},
		nav: {},
		title: {
			searchResultsFor: 'Search results for',
			error404: 'Error 404<br/> Nothing found.',
		},
		button: {
			backToHome: 'back to Homepage',
			backToRessources: 'back to Resources',
			backToTeaching: 'back to Teaching',
			backToActivities: 'back to Activities',
			readMore: 'read more',
			readLess: 'read less',
		},
		message: {
			nothingFound: 'Nothing found'
		},
		searchResults: {
			news: 'News',
			activities: 'Activities',
			pages: 'Pages',
			team: 'Team',
			resources: 'Resources',
			teaching: 'Teaching',
		},
	},
}
