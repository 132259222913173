<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsSplashScreen></TsSplashScreen>

	2019-06-08	init

-->

<template>
	<a class="TsSplashScreen" :class="elmClasses" role="button" @click="onClickSplashScreen" v-if="isInDom" data-dev-name="TsSplashScreen">
		<div class="TsSplashScreen__inner background background--white"></div>
	</a>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'TsSplashScreen',
		components: {},
		mixins: [],
		props: {},
		data(){
			return {
				isVisible : undefined,
				isInDom   : undefined,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isVisible ) classes.push( this.$options.name + '--isVisible')
				else classes.push( this.$options.name + '--isHidden')

				return classes
			},
		},
		methods: {
			onClickSplashScreen( doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClickSplashScreen()' )
					//console.log('key:', value)
					console.groupEnd()
				}

				this.isVisible = !this.isVisible

				sessionStorage.setItem('TsSplashScreen isVisible', this.isVisible)
			},
		},
		created(){},
		mounted(){
			let sessionValue = sessionStorage.getItem('TsSplashScreen isVisible')
			let isInDom      = sessionValue === 'false' ? false : true

			this.isInDom     = isInDom
			this.isVisible   = sessionValue === 'false' ? false : true
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsSplashScreen { // debug
		[showBorders2] & {}
	}
	.TsSplashScreen { // layout
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		display: flex;
		overflow: hidden;
		z-index: 20;

		&--isVisible { pointer-events: auto; }
		&--isHidden { pointer-events: none; }

		&__inner { flex-grow: 1; }
		&--isVisible &__inner { transform: translateY(0%); }
		&--isHidden &__inner { transform: translateY(-100%); }

	}
	.TsSplashScreen { // styling
		&__inner {
			transition: all 0.5s ease;

			background-image: url('./../assets/images/keyvisual.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
