<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TsNewsPostTeaser></TsNewsPostTeaser>

	2019-06-08	init

-->

<template>
	<div class="TsNewsPostTeaser" :class="elmClasses" :is="tag" data-dev-name="TsNewsPostTeaser">
		<router-link class="TsNewsPostTeaser__inner background background--white" :to="app.getLinkWithoutHostname( post.link )">

			<div class="TsNewsPostTeaser__outline TsNewsPostTeaser__outline--first" :style="firstOutlineStyles">
				<div class="TsNewsPostTeaser__outline TsNewsPostTeaser__outline--second" :style="secondOutlineStyles">
					<div class="TsNewsPostTeaser__outline TsNewsPostTeaser__outline--third" :style="thirdOutlineStyles">
						<div class="TsNewsPostTeaser__outline TsNewsPostTeaser__outline--fourth" :style="fourthOutlineStyles">

							<TsMainCategoriesBar
								class="TsNewsPostTeaser__mainCategoriesBar font font--sans font--bold font--sizeMini"
								:style="categoriesBarStyles"
								:mainCategories="_.get( post, 'acf.mainCategories')"
							></TsMainCategoriesBar>

							<MhImage :imageObject="_.get( post, 'acf.teaserImage')" :mode="'cover'"></MhImage>

							<h5 class="TsNewsPostTeaser__overTitle hyphenate hyphenate font font--mono font--sizeTiny" v-html="_.get( post, 'acf.overTitle')"></h5>
							<h2 class="TsNewsPostTeaser__mainTitle hyphenate hyphenate font font--sans font--bold" :class="_.get( post, 'acf.teaserImage') ? 'font--sizeMini' : 'font--sizeLarge'" v-html="_.get( post, 'title.rendered')"></h2>

						</div>
					</div>
				</div>
			</div>

		</router-link>
		<!--
		<pre name="post">{{post}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import TsMainCategoriesBar from '@/molecules/TsMainCategoriesBar.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'TsNewsPostTeaser',
		components: {
			TsMainCategoriesBar,
			MhImage,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			post: {
				type     : [Object, Boolean],
				default  : false,
			},
			tag: {
				type     : [String, Boolean],
				default  : 'div',
			},
		},
		data(){
			return {
				outlineBorderWidth : 2,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			firstOutlineStyles(){
				const mainCategoryId = this._.get( this.post, 'acf.mainCategories[0]' )
				const mainCategory   = this.app.getMainCategoryById( mainCategoryId )
				let   styles         = {}

				if( mainCategory ){
					styles.border = this.outlineBorderWidth + 'px solid ' + this._.get( mainCategory, 'acf.color' )
				}

				return styles
			},
			secondOutlineStyles(){
				const mainCategoryId = this._.get( this.post, 'acf.mainCategories[1]' )
				const mainCategory   = this.app.getMainCategoryById( mainCategoryId )
				let   styles         = {}

				if( mainCategory ){
					styles.border = this.outlineBorderWidth + 'px solid ' + this._.get( mainCategory, 'acf.color' )
				}

				return styles
			},
			thirdOutlineStyles(){
				const mainCategoryId = this._.get( this.post, 'acf.mainCategories[2]' )
				const mainCategory   = this.app.getMainCategoryById( mainCategoryId )
				let   styles         = {}

				if( mainCategory ){
					styles.border = this.outlineBorderWidth + 'px solid ' + this._.get( mainCategory, 'acf.color' )
				}

				return styles
			},
			fourthOutlineStyles(){
				const mainCategoryId = this._.get( this.post, 'acf.mainCategories[3]' )
				const mainCategory   = this.app.getMainCategoryById( mainCategoryId )
				let   styles         = {}

				if( mainCategory ){
					styles.border = this.outlineBorderWidth + 'px solid ' + this._.get( mainCategory, 'acf.color' )
				}

				return styles
			},
			categoriesBarStyles(){
				const mainCategories = this._.get( this.post, 'acf.mainCategories', [] )
				const mainCategoryId = this._.last( mainCategories )
				const mainCategory   = this.app.getMainCategoryById( mainCategoryId )
				let   styles         = {}

				if( mainCategory ){
					styles.borderBottom = this.outlineBorderWidth + 'px solid ' + this._.get( mainCategory, 'acf.color' )
				}

				return styles
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.TsNewsPostTeaser { // debug
		[showBorders3] & {
			outline: 1px solid red;

			.TsMainCategoriesBar { background-color: fade( red, 15 ); }
		}
	}
	.TsNewsPostTeaser { // layout
		display: flex;
		flex-direction: column;

		&__inner {
			display: flex;
			flex-direction: column;
			height: 100%;
			//flex-grow: 1;
			//flex-grow: 1;
		}
		&__outline {
			height: 100%;
		}
		.MhImage { // force 16:9 for images
			padding-bottom: 56.25% !important;
		}
	}
	.TsNewsPostTeaser { // styling
		&__inner { color: inherit; }

		// shadow on hover
		&__inner {
			transition: all 0.1s ease;
		}
		&__inner:hover {
			transform: translateY(-3px);
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		}

		&__outline {}
		&__outline--first { border: 2px solid black; border-radius: 3px; }

		&__mainCategoriesBar > * { height: 2em; padding-left: 7px; padding-right: 7px; }
		&__overTitle {
			padding: 6px 7px 0px;

			@media @mq[md] { font-size: 12px; }
		}
		&__mainTitle {
			padding: 6px 7px 10px 7px;

			@media @mq[md] { font-size: 14px; }
			@media @mq[md] { padding: 6px 7px 7px 7px; }
		}
		&__mainTitle.font--sizeLarge {
			@media @mq[md] { font-size: 30px; }
		}



		// enlarge img on hover
		/*
		&__inner .MhImage { overflow: hidden; }
		&__inner .MhImage img { transition: all 0.2s ease; }
		&__inner:hover .MhImage img { transform: scale(1.02); }
		*/
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
